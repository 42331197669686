/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'warning': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.796 1.023a1 1 0 00-1.758 0L.122 11.93a1 1 0 00.88 1.477h11.83a1 1 0 00.879-1.477L7.796 1.023zm-.47 3.255a.5.5 0 00-1 0v3.005c0 .184.003.364.007.54a25.623 25.623 0 00.07 1.078l.026.239a.399.399 0 00.792 0l.022-.24a12.34 12.34 0 00.07-1.078c.009-.175.013-.355.013-.539V4.278zm-1.2 6.431a.843.843 0 00-.053.3c0 .11.017.213.053.307.04.09.09.17.153.239a.68.68 0 00.237.159c.09.04.187.061.29.061.102 0 .198-.02.29-.061a.68.68 0 00.236-.16.784.784 0 00.213-.545.768.768 0 00-.06-.3.709.709 0 00-.154-.245.757.757 0 00-.236-.165.696.696 0 00-.29-.062.696.696 0 00-.29.062.757.757 0 00-.236.165.848.848 0 00-.153.245z" _fill="#CE5769"/>'
  }
})
